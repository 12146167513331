import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, MenuItem, Typography } from '@mui/material';
import { SUMMARY_ACTION, YARD_CHECK_ITEM_TYPE } from 'utils/constants';
import { SelectStyled } from './YardCheckSummaryTabsStyled';

const { MISMATCHED, NEW, MATCHED } = YARD_CHECK_ITEM_TYPE;

const YardCheckSummaryActionDropdown = ({ status, onActionChange, disabled = false, defaultAction }) => {
    const [action, setAction] = useState(defaultAction || initialAction);

    const initialAction = useMemo(() => {
        switch (status) {
            case MISMATCHED:
                return SUMMARY_ACTION.REMOVED;
            case NEW:
                return SUMMARY_ACTION.ADD;
            case MATCHED:
                return SUMMARY_ACTION.UPDATE;
            default:
                return SUMMARY_ACTION.SKIP;
        }
    }, [status]);

    const options = useMemo(() => {
        switch (status) {
            case MISMATCHED:
                return [
                    { value: SUMMARY_ACTION.REMOVED, label: 'Remove' },
                    { value: SUMMARY_ACTION.SKIP, label: 'Skip' },
                ];
            case NEW:
                return [
                    { value: SUMMARY_ACTION.ADD, label: 'Add' },
                    { value: SUMMARY_ACTION.SKIP, label: 'Skip' },
                ];
            case MATCHED:
                return [
                    { value: SUMMARY_ACTION.UPDATE, label: 'Update' },
                    { value: SUMMARY_ACTION.SKIP, label: 'Skip' },
                ];
            default:
                return [];
        }
    }, [status]);

    const handleActionChange = useCallback(
        (event) => {
            const newAction = event.target.value;
            setAction(newAction);
            onActionChange(newAction);
        },
        [onActionChange]
    );

    useEffect(() => {
        if (defaultAction) {
            setAction(defaultAction);
        }
    }, [defaultAction]);

    return (
        <Box display="flex" alignItems="center">
            <FormControl size="small" style={{ minWidth: 120 }}>
                <SelectStyled value={action} onChange={handleActionChange} size="small" disabled={disabled}>
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value} size="small">
                            <Typography fontSize={15}>{option.label}</Typography>
                        </MenuItem>
                    ))}
                </SelectStyled>
            </FormControl>
        </Box>
    );
};

YardCheckSummaryActionDropdown.propTypes = {
    status: PropTypes.oneOf([MISMATCHED, NEW, MATCHED]).isRequired,
    onActionChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    defaultAction: PropTypes.string,
};

export default React.memo(YardCheckSummaryActionDropdown);
