import { createSlice } from '@reduxjs/toolkit';

const clientPersonnelSlice = createSlice({
    name: 'clientPersonnel',
    initialState: {
        authorizedLocations: [],
        loading: false,
        error: null,
    },
    reducers: {
        fetchAuthorizedLocationsRequest: (state) => {
            state.loading = true;
        },
        fetchAuthorizedLocations: (state, action) => {
            state.loading = false;
            state.authorizedLocations = action.payload;
        },
        fetchAuthorizedLocationsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        resetState: (state) => {
            state.authorizedLocations = [];
            state.loading = false;
            state.error = null;
        },
    },
});

export const clientPersonnelActions = clientPersonnelSlice.actions;
export const clientPersonnelReducer = clientPersonnelSlice.reducer;
