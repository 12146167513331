import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { audioConfigurationActions } from './AudioConfigurationSlice';
import { fetchAllLocations } from 'services/userLocationService';
import notificationsHandler from 'utils/notificationsHandler';
import {
    fetchAudioConfiguredCameras,
    fetchAudioTypes,
    fetchLocationMonitoringObjects,
    fetchMonitoringObjectCameras,
    updateCameraAudioType,
} from 'services/monitoringService';
import { GET_ALL_CAMERAS_TYPE } from '../constants';

function* httpRequest(request, ...args) {
    try {
        yield put(audioConfigurationActions.requestStart(''));
        const data = yield call(request, ...args);
        yield put(audioConfigurationActions.requestSucces());
        return data;
    } catch (err) {
        yield put(audioConfigurationActions.requestError());
        throw err;
    }
}

function* getLocationMonitoringObjects({ payload: locationId }) {
    try {
        const data = yield call(httpRequest, fetchLocationMonitoringObjects, locationId, true);
        yield put(
            audioConfigurationActions.setMonitoringObjects(
                data.map((mo) => {
                    mo.label = createLabel(mo);
                    return mo;
                })
            )
        );
    } catch (err) {
        yield call(notificationsHandler, { err, title: `Error fetching monitoring objects for location id=${locationId}` });
    }
}

function* getAllLocations() {
    try {
        const data = yield call(httpRequest, fetchAllLocations);
        yield put(audioConfigurationActions.setAllLocations(data));
    } catch (err) {
        yield call(notificationsHandler, { err, title: 'Error fetching all locations' });
    }
}

function* getAudioTypes() {
    try {
        const data = yield call(httpRequest, fetchAudioTypes);
        const formatedData = data.map((audioType) => ({
            value: audioType,
            label: audioType
                .split('_')
                .map((word, i) => {
                    if (i === 0) {
                        return word[0] + word.slice(1).toLowerCase();
                    }
                    return word;
                })
                .join(' '),
        }));
        yield put(audioConfigurationActions.setAudioTypes(formatedData));
    } catch (err) {
        yield call(notificationsHandler, { err, title: 'Error fetching audio types' });
    }
}

function* getAudioConfiguredCameras() {
    try {
        yield put(audioConfigurationActions.requestStart(GET_ALL_CAMERAS_TYPE));
        const data = yield call(fetchAudioConfiguredCameras);
        const formatedData = data.map((record) => {
            const mo = record.monitoringObjects?.[0];
            if (mo) mo.label = createLabel(mo);
            return record;
        });
        yield put(audioConfigurationActions.setAudioConfiguredCameras(formatedData));
        yield put(audioConfigurationActions.requestSucces());
    } catch (err) {
        yield call(notificationsHandler, { err, title: 'Error fetching audio configured cameras' });
        yield put(audioConfigurationActions.requestError());
    }
}

function* getInitialData() {
    yield call(getAudioConfiguredCameras);
    yield all([call(getAudioTypes), call(getAllLocations)]);
}

function* getMonitoringObjectCameras({ payload: monitoringObjectId }) {
    try {
        const data = yield call(httpRequest, fetchMonitoringObjectCameras, monitoringObjectId);
        yield put(audioConfigurationActions.setMonitoringObjectCameras(data));
    } catch (err) {
        yield call(notificationsHandler, { err, title: 'Error fetching cameras for selected monitoring object' });
    }
}

function* submitChanges({
    payload: { selectedLocation, selectedMonitoringObject, selectedCamera, selectedAudioType: audioType, extensionNumber, removeAudioType },
}) {
    let isFormValid = true;
    const locationName = selectedLocation?.yardName;
    const monitoringObject = selectedMonitoringObject;
    const id = selectedCamera?.id;
    const name = selectedCamera?.name;

    if (!locationName || !id || !name || (!removeAudioType && !audioType)) isFormValid = false;
    if (!removeAudioType && audioType?.toLowerCase()?.includes('extension') && !extensionNumber) isFormValid = false;
    if (!isFormValid) {
        yield call(notificationsHandler, { title: 'Please review your inputs', variant: 'info' });
        return;
    }

    try {
        yield call(httpRequest, updateCameraAudioType, {
            id,
            name,
            locationName,
            ...(monitoringObject && { monitoringObjects: [monitoringObject] }),
            audioType: removeAudioType ? null : audioType,
            extensionNumber: removeAudioType ? '' : String(extensionNumber),
        });
        yield call(getAudioConfiguredCameras);
        yield put(audioConfigurationActions.filterCameras());
        yield put(audioConfigurationActions.setDialog({ open: false }));
        yield call(notificationsHandler, { title: 'Camera audio type saved', variant: 'success' });
    } catch (err) {
        yield call(notificationsHandler, { err, title: 'Error saving camera audio type' });
    }
}

function* openExistingRecord({ payload: record }) {
    yield put(audioConfigurationActions.setDialog({ open: true, mode: 'edit' }));
    yield put(audioConfigurationActions.setSelectedLocation({ yardName: record?.yardName }));
    if (record?.monitoringObjects?.[0]) yield put(audioConfigurationActions.setSelectedMonitoringObject(record?.monitoringObjects[0]));
    yield put(audioConfigurationActions.setSelectedCamera({ name: record?.name, id: record?.id }));
    yield put(audioConfigurationActions.setSelectedAudioType(record?.audioType));
    yield put(audioConfigurationActions.setExtensionNumber(+record?.extensionNumber));
}

//watchers

function* onGetLocationMonitoringObjects() {
    yield takeLatest(audioConfigurationActions.getMonitoringObjects, getLocationMonitoringObjects);
}

function* onGetAllLocations() {
    yield takeLatest(audioConfigurationActions.getAllLocations, getAllLocations);
}

function* onGetAudioTypes() {
    yield takeLatest(audioConfigurationActions.getAudioTypes, getAudioTypes);
}

function* onGetAudioConfiguredCameras() {
    yield takeLatest(audioConfigurationActions.getAudioConfiguredCameras, getAudioConfiguredCameras);
}

function* onGetInitialData() {
    yield takeLatest(audioConfigurationActions.getInitialData, getInitialData);
}

function* onGetMonitoringObjectCameras() {
    yield takeLatest(audioConfigurationActions.getMonitoringObjectCameras, getMonitoringObjectCameras);
}

function* onSubmitChanges() {
    yield takeLatest(audioConfigurationActions.submitChanges, submitChanges);
}

function* onOpenExistingRecord() {
    yield takeLatest(audioConfigurationActions.openExistingRecord, openExistingRecord);
}

export const audioConfigurationSaga = function* () {
    yield all([
        call(onGetAllLocations),
        call(onGetLocationMonitoringObjects),
        call(onGetAudioTypes),
        call(onGetAudioConfiguredCameras),
        call(onGetInitialData),
        call(onGetMonitoringObjectCameras),
        call(onSubmitChanges),
        call(onOpenExistingRecord),
    ]);
};

//utils
const createLabel = (mo) => `${mo.name} (${mo.objectType})`;
