import { call, put, all, takeLatest } from 'redux-saga/effects';
import { clientPersonnelActions } from './clientPersonnelSlice';
import { fetchPersonnelAuthorizedLocations } from 'services/clientPersonnelService';

export function* fetchPesonnelAuthorizedLocations(action) {
    const { personnelId } = action.payload;
    try {
        const data = yield call(fetchPersonnelAuthorizedLocations, personnelId);
        yield put(clientPersonnelActions.fetchAuthorizedLocations(data));
    } catch (error) {
        yield put(clientPersonnelActions.fetchAuthorizedLocationsError(error.message));
    }
}

export function* onFetchPersonnelAuthorizedLocations() {
    yield takeLatest(clientPersonnelActions.fetchAuthorizedLocationsRequest.type, fetchPesonnelAuthorizedLocations);
}

export function* clientPersonnelSaga() {
    yield all([call(onFetchPersonnelAuthorizedLocations)]);
}

export default clientPersonnelSaga;
