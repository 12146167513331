import { axiosLasta } from 'config';
import { CACHE_NAME_CLIENT_PERSONNEL_LIST, CACHE_NAME_CLIENT_PERSONNEL_LIST_DETAILED } from 'services/constants/cacheNameConstants';

export const fetchClientPersonnel = async () => {
    const url = 'client/personnel/all';
    const { data } = await axiosLasta(url, {
        cache: {
            enabled: true,
            key: CACHE_NAME_CLIENT_PERSONNEL_LIST,
        },
    });
    return data || [];
};

export const fetchClientPersonnelDetailed = async () => {
    const url = 'client/personnel/all/detailed';
    const { data } = await axiosLasta(url, {
        cache: {
            enabled: true,
            key: CACHE_NAME_CLIENT_PERSONNEL_LIST_DETAILED,
        },
    });
    return data || [];
};

export const createClientPersonnel = async (payload) => {
    const response = await axiosLasta.post('client/personnel', payload, {
        cache: {
            prune: [CACHE_NAME_CLIENT_PERSONNEL_LIST],
        },
    });
    const { data } = response;
    return data;
};

export const editClientPersonnel = async (payload) => {
    const response = await axiosLasta.put('client/personnel', payload, {
        cache: {
            prune: [CACHE_NAME_CLIENT_PERSONNEL_LIST],
        },
    });
    const { data } = response;
    return data;
};

export const fetchPersonnelAuthorizedLocations = async (personnelId) => {
    const url = `client/access-personnel/${personnelId}/locations`;
    const { data } = await axiosLasta(url);
    return data || [];
};
